'use client';
import React, { PropsWithChildren } from 'react';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { useMantineTheme } from '@mantine/core';
import { useUnsavedChanges } from './UnsavedChangesProvider';

const ProgressProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useMantineTheme();
  const { unsavedChanges } = useUnsavedChanges();

  return (
    <>
      {children}
      {Object.values(unsavedChanges).some((value) => value) ? null : (
        <ProgressBar
          height="2px"
          color={theme.primaryColor}
          options={{ showSpinner: false }}
          shallowRouting
        />
      )}
    </>
  );
};

export default ProgressProvider;

import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { isEmpty } from 'lodash';
import { usePathname } from 'next/navigation';
import React, { createContext, PropsWithChildren, useState } from 'react';

type UnsavedChangesContextType = {
  unsavedChanges: Record<string, boolean>;
  setUnsavedChanges: (key: string, unsavedChanges: boolean) => void;
  beforeNavigate: (onConfirm: () => void) => void;
};

const UnsavedChangesContext = createContext({} as UnsavedChangesContextType);

export const useUnsavedChanges = () => React.useContext(UnsavedChangesContext);

export default function UnsavedChangesProvider({ children }: PropsWithChildren) {
  const pathName = usePathname();
  const [unsavedChanges, setUnsavedChanges] = useState<Record<string, boolean>>({});

  function handleSetUnsavedChanges(key: string, unsavedChanges: boolean) {
    setUnsavedChanges((prev) => ({ ...prev, [key]: unsavedChanges }));
  }

  useLeavePageConfirm(Object.values(unsavedChanges).some((value) => value));

  function handleBeforeNavigate(onConfirm: () => void) {
    if (
      Object.values(unsavedChanges).some((value) => value) &&
      (pathName?.startsWith('/assistentes') || pathName?.startsWith('/missoes'))
    ) {
      modals.openConfirmModal({
        title: 'Você tem alterações não salvas',
        children: <Text size="sm">Deseja descartar as alterações?</Text>,
        labels: { cancel: 'Cancelar', confirm: 'Descartar' },
        onConfirm: () => {
          onConfirm();
          setUnsavedChanges({});
        },
      });
      return;
    }
    onConfirm();
  }

  if (
    !pathName?.startsWith('/assistentes') &&
    !isEmpty(unsavedChanges) &&
    !pathName?.startsWith('/missoes')
  ) {
    setUnsavedChanges({});
  }

  return (
    <UnsavedChangesContext.Provider
      value={{
        unsavedChanges,
        setUnsavedChanges: handleSetUnsavedChanges,
        beforeNavigate: handleBeforeNavigate,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
}

function useLeavePageConfirm(active = true) {
  const beforeUnloadListener = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = '');
  };

  React.useEffect(() => {
    if (active) {
      addEventListener('beforeunload', beforeUnloadListener);

      return () => {
        removeEventListener('beforeunload', beforeUnloadListener);
      };
    }
  }, [active]);
}

import { createContext, useContext } from 'react';
import { createStore, useStore as useZustandStore } from 'zustand';
import { PreloadedStoreInterface } from '@/providers/StoreProvider';
import { useShallow } from 'zustand/react/shallow';
import { ApiSlice, createApiSlice } from './slices/ApiSlice';
import { AppDataSlice, createAppDataSlice } from './slices/AppDataSlice';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StoreInterface extends ApiSlice, AppDataSlice {}

function getDefaultInitialState() {
  return {} as StoreInterface;
}

export type StoreType = ReturnType<typeof initializeStore>;

const storeContext = createContext<StoreType | null>(null);

export const Provider = storeContext.Provider;

export function useStore<T>(selector: (state: StoreInterface) => T) {
  const store = useContext(storeContext);

  if (!store) throw new Error('Store is missing the provider');

  return useZustandStore(store, useShallow(selector));
}

export function initializeStore(preloadedState: PreloadedStoreInterface) {
  const initialData = preloadedState.initialData;

  return createStore<StoreInterface>((...args) => ({
    ...getDefaultInitialState(),
    ...createApiSlice(...args),
    ...createAppDataSlice(...args),
    ...initialData,
  }));
}

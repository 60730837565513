import { MantineProvider } from '@mantine/core';
import React, { PropsWithChildren } from 'react';
import { Notifications } from '@mantine/notifications';
import { NavigationProgress } from '@mantine/nprogress';
import mantineTheme from '@/styles/mantineTheme';
import { ModalsProvider } from '@mantine/modals';

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <MantineProvider theme={mantineTheme}>
      <ModalsProvider />
      <Notifications position="top-right" />
      <NavigationProgress />
      {children}
    </MantineProvider>
  );
};

export default ThemeProvider;

import { Badge, Button, createTheme } from '@mantine/core';
import { colors, spacings, fontSize } from './theme';

const button = Button.extend({
  defaultProps: {
    variant: 'gradient',
    // Align icon vertically with text
    styles: { section: { marginBottom: 4, height: 20, width: 20 } },
  },
});

const badge = Badge.extend({
  // Avoid uppercase in badge
  defaultProps: { styles: { label: { textTransform: 'none' } } },
});

const mantineTheme = createTheme({
  colors,
  components: { Button: button, Badge: badge },
  spacing: spacings,
  fontSizes: fontSize,
  primaryColor: 'primary',
  defaultRadius: 'md',
  defaultGradient: {
    from: 'primary',
    to: 'accent',
    deg: -45,
  },
});

export type ColorsType = keyof typeof colors;

export default mantineTheme;

import { setAxiosAuthentication } from './axios';
import {
  NOTIFICATION_BANNER,
  THREADS_READ_STATUS,
  USER_TOKEN,
  CHAT_FILTERS,
  DASHBOARD_DATE_RANGE,
  DASHBOARD_HAS_DATA,
} from '@/utils/storageKeys';
import { deleteCookie } from 'cookies-next';

export function getInitials(name?: string) {
  if (!name) return undefined;

  const [firstName, lastName] = name.split(' ');

  if (!firstName) return undefined;
  if (!lastName) return `${firstName.slice(0, 2)}`;

  const firstLetter = firstName[0] || '';
  const secondLetter = lastName[0] || firstName[1] || '';

  if (!firstLetter && !secondLetter) return undefined;

  return `${firstLetter}${secondLetter}`;
}

export const cleanUserLocalState = () => {
  deleteCookie(USER_TOKEN);
  deleteCookie(DASHBOARD_HAS_DATA);
  deleteCookie(DASHBOARD_DATE_RANGE);
  setAxiosAuthentication(null);
  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(NOTIFICATION_BANNER);
  localStorage.removeItem(THREADS_READ_STATUS);
  localStorage.removeItem(CHAT_FILTERS);
};
